import React from "react"
import { Link } from "gatsby"
import Layout from "../components/Layout"
import { ContentSubHeading, ContentHeading } from "../components/styles"
import SEO from "../components/SEO"
const slug = require("slug")

const CompareBox = ({ name }) => (
  <Link
    className="p-4 h-36 text-base bg-gray-50 hover:bg-gradient-to-t hover:shadow-lg shadow-sm hover:from-gray-50 hover:to-white transition duration-300 ease-in-out"
    to={`/product/compare/${slug(name)}`}
  >
    <h3 className="pb-4 text-gray-900 font-semibold">{name}</h3>
    {/* <p className="text-sm pb-4 text-gray-600">{description}</p> */}
    {/* <p className="text-sm underline hover:no-underline text-indigo-700 hover:text-indigo-900">
                  {name} use cases
                </p> */}
  </Link>
)

export default ({ pageContext: { companies } }) => (
  <Layout>
    <SEO title={"All Comparisons | Pricelist"} />
    <div class="relative px-4 sm:px-6 lg:px-8">
      <div class="mx-auto max-w-prose text-lg">
        <h1>
          <ContentSubHeading>Pricelist and others</ContentSubHeading>
          <ContentHeading>Feature Comparisons</ContentHeading>
        </h1>
        <p class="mt-8 text-gray-500 text-xl leading-8">
          Pricelist represents the state of the art of modern go-to-market
          software. It combines the best features available in the market and
          extends them with collaborative and analytical functionality to
          provide an intuitive and user-friendly experience.
        </p>

        <p class="mt-8 text-gray-500 text-xl leading-8">
          Unlike existing solutions, Pricelist provides a common platform for
          all teams that support go-to-market activities from product
          introduction to promotional planning and end of life.
        </p>

        <h3 className="mt-8 py-4 text-gray-800 font-bold">
          Compare features between Pricelist and other solutions:
        </h3>

        <div className="grid gap-5 grid-cols-1 mt-4 sm:grid-cols-3">
          {companies
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map(({ name }) => (
              <CompareBox name={name} />
            ))}
        </div>
      </div>
      <div class="prose prose-indigo prose-lg mt-6 mx-auto text-gray-500">
        <p></p>
      </div>
    </div>
  </Layout>
)
